// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/builds/smamudhan/soul-journal/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-app-js": () => import("/builds/smamudhan/soul-journal/src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-index-js": () => import("/builds/smamudhan/soul-journal/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("/builds/smamudhan/soul-journal/src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-privacy-js": () => import("/builds/smamudhan/soul-journal/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-register-js": () => import("/builds/smamudhan/soul-journal/src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-resetpassword-js": () => import("/builds/smamudhan/soul-journal/src/pages/resetpassword.js" /* webpackChunkName: "component---src-pages-resetpassword-js" */),
  "component---src-pages-terms-js": () => import("/builds/smamudhan/soul-journal/src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/builds/smamudhan/soul-journal/.cache/data.json")

